<template>
  <section id="add-test-profile" v-loading="loading">
    <div class="container-small-table">
      <div class="addTests">
        <div class="addTests__navbar">
          <el-row type="flex" align="middle" gutter="20">
            <el-col :span="4">
              <div class="title">
                <div class="icon-block">
                  <div class="icon-text">
                    <h3>Select Tests</h3>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="8">
              <div class="search-tests-block">
                <div class="selected-test mr-15">
                  <el-select
                    filterable
                    v-model="case_type"
                    placeholder="Select Case type"
                    :multiple-limit="getCaseTypeLimit"
                    @change="getAllTests"
                  >
                    <el-option
                      v-for="(caseType, index) of getCaseTypes"
                      :key="index"
                      :value="caseType.value"
                      :label="caseType.case_name"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </el-col>
            <el-col :lg="4">
              <div class="search-tests-block">
                <div class="search-input">
                  <el-input
                    placeholder="Test profile name"
                    v-model="testProfileName"
                  ></el-input>
                  <p class="err error-break">
                    <FormError errorName="title"></FormError>
                  </p>
                </div>
              </div>
            </el-col>
            <el-col :lg="6">
              <el-form>
                <el-input
                  placeholder="search"
                  suffix-icon="el-icon-search"
                  clearable
                  v-model="searchString"
                ></el-input> </el-form
            ></el-col>
          </el-row>
        </div>
        <div class="addTests__testLists" v-if="case_type" v-loading="loading">
          <el-row>
            <el-col :span="14">
              <div class="selection-tests-group">
                <div class="test-panels">
                  <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane
                      v-for="(caseType, index) of selectedCaseTypes"
                      :key="index"
                      :label="caseType.label"
                      :name="caseType.label"
                    >
                      <div class="search-checkbox">
                        <ul class="" v-if="caseType.value == 'TOXICOLOGY'">
                          <div class="checkbox-order newProfileTox">
                            <el-checkbox-group
                              v-model="toxicology_ereq_form"
                              class="search-checkbox checklist-grid"
                            >
                              <el-checkbox
                                v-for="option in availableOptions"
                                :key="option.label"
                                :label="option.label"
                                :disabled="option.disabled"
                                @change="
                                  updateCheckedOptions(option.label, $event)
                                "
                              />
                            </el-checkbox-group>
                          </div>
                          <li class="each-test">
                            <el-tabs v-model="activeToxicologyTab">
                              <el-tab-pane name="screening" v-if="showScreening">
                                <template #label>
                                  <el-checkbox
                                    class="position-relative-top-3"
                                    :model-value="
                                      isMultiCheckAll('TOXICOLOGY', [
                                        'SCREENING',
                                        'SCREENING_VALIDITY',
                                      ])
                                    "
                                    @change="
                                      toggleCheckbox(
                                        'screening',
                                        ['SCREENING', 'SCREENING_VALIDITY'],
                                        true,
                                        $event
                                      )
                                    "
                                  ></el-checkbox>
                                  <span class="ml-10">Screening</span>
                                </template>

                                <ul class="add-tests-selection">
                                  <li
                                    class="each-test"
                                    v-for="(
                                      category, index
                                    ) of getCaseCategoryBasedData(
                                      'TOXICOLOGY',
                                      ['SCREENING', 'SCREENING_VALIDITY'],
                                      'screening'
                                    )"
                                    :key="index"
                                  >
                                    <el-collapse
                                      v-model="activeNameCollapseScreening"
                                      accordion
                                    >
                                      <el-collapse-item :name="index">
                                        <template #title>{{
                                          category.category
                                        }}</template>
                                        <el-checkbox
                                          class="test-category"
                                          :model-value="
                                            isCheckAll(
                                              category.case_type_code,
                                              category.category_code
                                            )
                                          "
                                          @change="
                                            checkAll(
                                              $event,
                                              category.case_type_code,
                                              category.category_code,
                                              category.category
                                            )
                                          "
                                          >All
                                        </el-checkbox>

                                        <el-checkbox-group v-model="testsData">
                                          <div
                                            v-for="(
                                              item, index
                                            ) of getCategoryWiseTestsData(
                                              category.case_type_code,
                                              category.category_code
                                            )"
                                            :key="index"
                                          >
                                            <div class="each-check-box">
                                              <el-checkbox
                                                :label="item._id"
                                                :value="item"
                                                >{{ item.name }}</el-checkbox
                                              >
                                            </div>
                                          </div>
                                        </el-checkbox-group>
                                      </el-collapse-item>
                                    </el-collapse>
                                  </li>
                                </ul>
                              </el-tab-pane>
                              <el-tab-pane name="confirmation" v-if="showConfirmation">
                                <template #label>
                                  <el-checkbox
                                    class="position-relative-top-3"
                                    :model-value="
                                      isMultiCheckAll(
                                        'TOXICOLOGY',
                                        [
                                          'SCREENING',
                                          'ETG/ETS',
                                          'SCREENING_VALIDITY',
                                          'ORAL_FLUID_TOX_CONFIRMATION',
                                          'D_L_Isomer',
                                        ],
                                        false
                                      )
                                    "
                                    @change="
                                      toggleCheckbox(
                                        'confirmation',
                                        [
                                          'SCREENING',
                                          'ETG/ETS',
                                          'SCREENING_VALIDITY',
                                          'ORAL_FLUID_TOX_CONFIRMATION',
                                          'D_L_Isomer',
                                        ],
                                        false,
                                        $event
                                      )
                                    "
                                  ></el-checkbox>
                                  <span class="ml-10">Confirmation</span>
                                </template>

                                <ul class="add-tests-selection">
                                  <li
                                    class="each-test"
                                    v-for="(
                                      category, index
                                    ) of getCaseCategoryBasedData(
                                      'TOXICOLOGY',
                                      [
                                        'SCREENING',
                                        'ETG/ETS',
                                        'SCREENING_VALIDITY',
                                        'ORAL_FLUID_TOX_CONFIRMATION',
                                        'D_L_Isomer',
                                      ],
                                      'confirmation',
                                      false
                                    )"
                                    :key="index"
                                  >
                                    <el-collapse
                                      v-model="activeNameCollapse"
                                      accordion
                                    >
                                      <el-collapse-item
                                        :name="index"
                                        class="screening"
                                      >
                                        <template #title>
                                          <el-checkbox
                                            class="el-collapse-checkbox"
                                            style="
                                              position: relative;
                                              top: 2px;
                                              padding: 0 10px 0 0;
                                            "
                                            :model-value="
                                              isCheckAll(
                                                category.case_type_code,
                                                category.category_code
                                              )
                                            "
                                            @change="
                                              checkAll(
                                                $event,
                                                category.case_type_code,
                                                category.category_code,
                                                category.category
                                              )
                                            "
                                          ></el-checkbox>
                                          {{ category.category }}</template
                                        >
                                        <el-checkbox
                                          class="test-category"
                                          :model-value="
                                            isCheckAll(
                                              category.case_type_code,
                                              category.category_code
                                            )
                                          "
                                          @change="
                                            checkAll(
                                              $event,
                                              category.case_type_code,
                                              category.category_code,
                                              category.category
                                            )
                                          "
                                          >All</el-checkbox
                                        >
                                        <el-checkbox-group v-model="testsData">
                                          <div
                                            v-for="(
                                              item, index
                                            ) of getCategoryWiseTestsData(
                                              category.case_type_code,
                                              category.category_code
                                            )"
                                            :key="index"
                                          >
                                            <div class="each-check-box">
                                              <el-checkbox
                                                :label="item._id"
                                                :value="item"
                                                >{{ item.name }}</el-checkbox
                                              >
                                            </div>
                                          </div>
                                        </el-checkbox-group>
                                      </el-collapse-item>
                                    </el-collapse>
                                  </li>
                                </ul>
                              </el-tab-pane>
                              <el-tab-pane name="etg/ets" v-if="showEtgEts">
                                <template #label>
                                  <el-checkbox
                                    class="position-relative-top-3"
                                    :model-value="
                                      isMultiCheckAll('TOXICOLOGY', ['ETG/ETS'])
                                    "
                                    @change="
                                      toggleCheckbox(
                                        'ETG/ETS',
                                        ['ETG/ETS'],
                                        true,
                                        $event
                                      )
                                    "
                                  ></el-checkbox>
                                  <span class="ml-10">ETG/ETS</span>
                                </template>

                                <ul class="add-tests-selection">
                                  <li
                                    class="each-test"
                                    v-for="(
                                      category, index
                                    ) of getCaseCategoryBasedData(
                                      'TOXICOLOGY',
                                      ['ETG/ETS'],
                                      'ETG/ETS'
                                    )"
                                    :key="index"
                                  >
                                    <el-collapse
                                      v-model="activeNameCollapseScreening"
                                      accordion
                                    >
                                      <el-collapse-item :name="index">
                                        <template #title>{{
                                          category.category
                                        }}</template>
                                        <el-checkbox
                                          class="test-category"
                                          :model-value="
                                            isCheckAll(
                                              category.case_type_code,
                                              category.category_code
                                            )
                                          "
                                          @change="
                                            checkAll(
                                              $event,
                                              category.case_type_code,
                                              category.category_code,
                                              category.category
                                            )
                                          "
                                          >All
                                        </el-checkbox>

                                        <el-checkbox-group v-model="testsData">
                                          <div
                                            v-for="(
                                              item, index
                                            ) of getCategoryWiseTestsData(
                                              category.case_type_code,
                                              category.category_code
                                            )"
                                            :key="index"
                                          >
                                            <div class="each-check-box">
                                              <el-checkbox
                                                :label="item._id"
                                                :value="item"
                                                >{{ item.name }}</el-checkbox
                                              >
                                            </div>
                                          </div>
                                        </el-checkbox-group>
                                      </el-collapse-item>
                                    </el-collapse>
                                  </li>
                                </ul>
                              </el-tab-pane>
                            </el-tabs>
                          </li>
                        </ul>
                        <ul class="" v-else>
                          <li
                            class="each-test"
                            v-for="(category, index) of getCaseCategoryData(
                              caseType.value
                            )"
                            :key="index"
                          >
                            <el-collapse v-model="activeNameCollapse" accordion>
                              <el-collapse-item :name="index">
                                <template #title>{{
                                  category.category
                                }}</template>
                                <el-checkbox
                                  :model-value="
                                    isCheckAll(
                                      category.case_type_code,
                                      category.category_code
                                    )
                                  "
                                  @change="
                                    checkAll(
                                      $event,
                                      category.case_type_code,
                                      category.category_code
                                    )
                                  "
                                  >All</el-checkbox
                                >
                                <el-checkbox-group v-model="testsData">
                                  <div
                                    v-for="(
                                      item, index
                                    ) of getCategoryWiseTestsData(
                                      category.case_type_code,
                                      category.category_code
                                    )"
                                    :key="index"
                                  >
                                    <div class="each-check-box">
                                      <el-checkbox
                                        :label="item._id"
                                        :value="item"
                                        >{{ item.name }}</el-checkbox
                                      >
                                    </div>
                                  </div>
                                </el-checkbox-group>
                              </el-collapse-item>
                            </el-collapse>
                          </li>
                        </ul>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="current-selected-tests">
                <div class="c_header">
                  <h4>CURRENT SELECTION</h4>
                </div>
                <div class="c_body">
                  <div class="inside-scroll">
                    <div
                      class="each-item"
                      v-for="(
                        categoryData, cindex
                      ) of getCategoriesOfSelectedTests"
                      :key="cindex"
                    >
                      <h4 class="category-name">{{ categoryData.category }}</h4>
                      <div class="selected-tests-list-group">
                        <ul class="selected-tests-list">
                          <li
                            v-for="(
                              test, index
                            ) of getCategoryWiseSelectedTests(
                              categoryData.category_code
                            )"
                            :key="index"
                          >
                            <span class="test-name">
                              {{ test.name }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="add-test-button">
                    <el-button @click="updateTests" v-if="editProfile">
                      Update Test
                    </el-button>

                    <el-button @click="addTests" v-else> Add Test </el-button>
                  </div>
                  <p class="err error-break">
                    <FormError errorName="lab_test_ids"></FormError>
                  </p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="addTests__noContent" v-if="!case_type">
          <img src="@/assets/images/icons/select-test-panel.svg" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import caseDataHelper from "@/mixins/caseDataHelper";
import caseConstants from "@/config/constants/caseConstants";
import AppHelper from "@/mixins/AppHelper";
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";

import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);
export default {
  components: { FormError },
  data() {
    return {
      case_type: "",
      search_case_types: [],
      testProfileName: "",
      selectedPanel: "",
      selectedPanelTests: [],
      selectedData: [],
      activeName: "",
      status: ["ACTIVE", "INACTIVE"],
      testsData: [],
      loading: false,
      searchString: null,
      editProfile: false,
      toxicology_ereq_form: [],
      options: caseConstants.TOX_CHECK_LIST,
      activeToxicologyTab: "screening",
      showScreening: false,
      showConfirmation: false,
      showEtgEts: false,
    };
  },
  mixins: [caseDataHelper, AppHelper],
  computed: {
    getCaseTypes() {
      return this.search_case_types;
    },
    getCaseTypeLimit() {
      return 1;
    },
    ...mapGetters("labTests", ["getAllLabTests"]),
    ...mapGetters("testProfiles", [
      "getTestProfilesAddStatus",
      "getSingleTestProfile",
      "getTestProfilesUpdateStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),

    getTestsInformationObject() {
      const obj = {};
      if (this.getAllLabTests) {
        this.getAllLabTests.forEach((el) => {
          obj[el._id] = el;
        });
      }
      return obj;
    },
    selectedTestsDetails() {
      const data = [];
      if (this.testsData) {
        this.testsData.forEach((el) => {
          if (el && this.getTestsInformationObject[el])
            data.push(this.getTestsInformationObject[el]);
        });
      }

      return data;
    },
    selectedTestsDetailsWithoutDuplicates() {
      let allData = [...this.selectedTestsDetails];
      return this.removeDuplicatesByProperty(allData, "_id");
    },
    getCategoriesOfSelectedTests() {
      if (
        !this.selectedTestsDetailsWithoutDuplicates ||
        !this.selectedTestsDetailsWithoutDuplicates.length
      ) {
        return [];
      }
      let tests = [...this.selectedTestsDetailsWithoutDuplicates];
      let selectedCategoriesKey = [];
      let selectedCategories = [];

      tests.forEach((test) => {
        if (selectedCategoriesKey.indexOf(test.category_code) == -1) {
          selectedCategoriesKey.push(test.category_code);
          selectedCategories.push({
            category_code: test.category_code,
            category: test.category,
          });
        }
      });
      return selectedCategories;
    },
    getCategoryWiseSelectedTests() {
      return (category) => {
        let tests = [...this.selectedTestsDetailsWithoutDuplicates];
        return tests.filter((test) => test.category_code == category);
      };
    },
    selectedCaseTypes() {
      return this.search_case_types.filter((e) => e.value == this.case_type);
    },
    availableOptions() {
      if (this.toxicology_ereq_form.includes("Screen Reflex to Confirmation")) {
        // If "Screen Reflex to Confirmation" is selected, enable "Confirm All Prescribed Medications of Interest"
        return this.options.map((option) => ({
          ...option,
          disabled:
            option.label !== "Screen Reflex to Confirmation" &&
            option.label !== "Confirm All Prescribed Medications of Interest" &&
            option.label !== "Confirm Tests Selected Below",
        }));
      } else if (
        this.toxicology_ereq_form.includes(
          "Confirm All Prescribed Medications of Interest"
        )
      ) {
        // If "Confirm All Prescribed Medications of Interest" is selected, enable "Screen Reflex to Confirmation"
        return this.options.map((option) => ({
          ...option,
          disabled:
            option.label !== "Confirm All Prescribed Medications of Interest" &&
            option.label !== "Screen Reflex to Confirmation" &&
            option.label !== "Confirm Tests Selected Below",
        }));
      } else if (
        this.toxicology_ereq_form.includes("Confirm Tests Selected Below")
      ) {
        // If "Confirm All Prescribed Medications of Interest" is selected, enable "Screen Reflex to Confirmation"
        return this.options.map((option) => ({
          ...option,
          disabled:
            option.label !== "Confirm All Prescribed Medications of Interest" &&
            option.label !== "Screen Reflex to Confirmation" &&
            option.label !== "Confirm Tests Selected Below",
        }));
      } else {
        // If neither is selected, enable only the respective options
        return this.options;
      }
    },
  },
  async mounted() {
    this.getLabCaseTypes();
    await this.$store.commit("errors/clear", null, { root: true });
    if (this.$route.params.profile_id) {
      await this.fetchTestProfiles();
      this.editProfile = true;
    }
  },
  methods: {
    setCheckListData() {
      this.activeToxicologyTab = null;
      this.showScreening = false;
      this.showConfirmation = false;
      this.showEtgEts = false;

      if (this.toxicology_ereq_form && this.toxicology_ereq_form.length === 1) {
        let options = this.toxicology_ereq_form[0];
        switch (options) {
          case "Screen and Confirm Tests Selected Below":
            this.activeToxicologyTab = "screening";
            this.showScreening = true;
            this.showConfirmation = true;
            this.showEtgEts = true;
            break;
          case "Screen and Confirm All Negative and Positive Results":
            this.activeToxicologyTab = "screening";
            this.showScreening = true;
            this.showConfirmation = true;
            this.showEtgEts = true;
            break;
          case "Screen Only":
            this.activeToxicologyTab = "screening";
            this.showScreening = true;
            break;
          case "Confirm All Negative and Positive Results":
            this.activeToxicologyTab = "confirmation";
            this.showConfirmation = true;
            this.showEtgEts = true;
            break;
          case "Confirm Tests Selected Below":
            this.activeToxicologyTab = "confirmation";
            this.showConfirmation = true;
            this.showEtgEts = true;
            break;
          case "Screen Reflex to Confirmation":
            this.activeToxicologyTab = "screening";
            this.showScreening = true;
            break;
          case "Confirm All Prescribed Medications of Interest":
            this.activeToxicologyTab = "screening";
            this.showScreening = true;
            break;
          default:
            console.warn("Unknown option:", options);
        }
      } else if (this.toxicology_ereq_form.length === 2) {
        if (
          this.toxicology_ereq_form.includes("Screen Reflex to Confirmation") &&
          this.toxicology_ereq_form.includes("Confirm All Prescribed Medications of Interest")
        ) {
          this.activeToxicologyTab = "screening";
          this.showScreening = true;
        }
      } else if (this.toxicology_ereq_form.length === 3) {
        if (
          this.toxicology_ereq_form.includes("Screen Reflex to Confirmation") &&
          this.toxicology_ereq_form.includes("Confirm All Prescribed Medications of Interest") &&
          this.toxicology_ereq_form.includes("Confirm Tests Selected Below")
        ) {
          this.activeToxicologyTab = "screening";
          this.showScreening = true;
          this.showConfirmation = true;
          this.showEtgEts = true;
        }
      }
    },
    getCategoriesWiseTestsData(caseType, categories, include = true) {
      let data = [];
      if (this.searchString) {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === caseType &&
            ((include && categories.indexOf(el.category_code) != -1) ||
              (!include && categories.indexOf(el.category_code) == -1)) &&
            (el.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
              el.category
                .toLowerCase()
                .includes(this.searchString.toLowerCase()))
        );
      } else {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === caseType &&
            ((include && categories.indexOf(el.category_code) != -1) ||
              (!include && categories.indexOf(el.category_code) == -1))
        );
      }

      return this.sortByKey(data, "name");
    },
    toggleCheckbox(type, category, include = true, check = true) {
      let data = [];

      if (include) {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code == "TOXICOLOGY" &&
            category.indexOf(el.category_code) != -1
        );
      } else {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === "TOXICOLOGY" &&
            category.indexOf(el.category_code) == -1
        );
      }

      if (check) {
        data.map((item) => {
          if (this.testsData.indexOf(item._id) == -1) {
            this.testsData.push(item._id);
          }
        });
      } else {
        check = false;
        data.map((item) => {
          if (this.testsData.indexOf(item._id) != -1) {
            this.testsData.splice(this.testsData.indexOf(item._id), 1);
          }
        });
      }
    },
    isMultiCheckAll(caseType, categories, include = true) {
      let selectedData = this.getCategoriesWiseTestsData(
        caseType,
        categories,
        include
      ).map((item) => item._id);

      return selectedData.every((item) => {
        return this.testsData.indexOf(item) !== -1;
      });
    },
    getCaseCategoryBasedData(caseType, category, type, include = true) {
      let data = "";
      let outputWithFullDetails = [];
      if (this.getAllLabTests) {
        if (include) {
          data = this.getAllLabTests.filter(
            (el) =>
              el.case_type_code &&
              el.case_type_code === caseType &&
              category.indexOf(el.category_code) != -1 &&
              (!this.searchString ||
                el.name
                  .toLowerCase()
                  .includes(this.searchString.toLowerCase()) ||
                el.category
                  .toLowerCase()
                  .includes(this.searchString.toLowerCase()))
          );
        } else {
          data = this.getAllLabTests.filter(
            (el) =>
              el.case_type_code &&
              el.case_type_code === caseType &&
              category.indexOf(el.category_code) == -1 &&
              (!this.searchString ||
                el.name
                  .toLowerCase()
                  .includes(this.searchString.toLowerCase()) ||
                el.category
                  .toLowerCase()
                  .includes(this.searchString.toLowerCase()))
          );
        }

        let flags = [],
          output = [],
          l = data.length,
          i;

        for (i = 0; i < l; i++) {
          if (flags[data[i].category_code]) continue;
          flags[data[i].category_code] = true;
          output.push(data[i].category_code);
          outputWithFullDetails.push(data[i]);
        }
      } else {
        return outputWithFullDetails;
      }
      return outputWithFullDetails;
    },
    updateCheckedOptions(label, checked) {
      const specialOptions = [
        "Screen Reflex to Confirmation",
        "Confirm All Prescribed Medications of Interest",
        "Confirm Tests Selected Below",
      ];
      if (checked) {
        // Add the option if it was checked
        if (!this.toxicology_ereq_form.includes(label)) {
          this.toxicology_ereq_form.push(label);
        }
        if (label === "Screen Reflex to Confirmation") {
          // Also add "Confirm All Prescribed Medications of Interest" if "Screen Reflex to Confirmation" is checked
          if (!this.toxicology_ereq_form.includes("Confirm All Prescribed Medications of Interest")) {
            this.toxicology_ereq_form.push("Confirm All Prescribed Medications of Interest");
          }
        }
      } else {
        // Remove the option if it was unchecked
        this.toxicology_ereq_form = this.toxicology_ereq_form.filter(option => option !== label);
        if (label === "Screen Reflex to Confirmation") {
          // Also remove "Confirm All Prescribed Medications of Interest" if "Screen Reflex to Confirmation" is unchecked
          this.toxicology_ereq_form = this.toxicology_ereq_form.filter(option => option !== "Confirm All Prescribed Medications of Interest");
        }
      }

      if (specialOptions.every(option => !this.toxicology_ereq_form.includes(option))) {
        // If none of the special options are selected, keep only the last selected option
        this.toxicology_ereq_form = this.toxicology_ereq_form.slice(-1);
      } else if (specialOptions.every(option => this.toxicology_ereq_form.includes(option))) {
        // If all special options are selected, keep all of them
        this.toxicology_ereq_form = specialOptions;
      } else {
        // If only some of the special options are selected, keep them along with any other selected options
        this.toxicology_ereq_form = this.toxicology_ereq_form.filter(option => specialOptions.includes(option));
      }
      
      this.setCheckListData();
      this.testsData = [];
    },
    getLabCaseTypes() {
      this.search_case_types = this.getLabBasedCaseTypes(
        caseConstants.TEST_CASE_TYPES_DATA
      );
    },
    removeSelectionFromTests(deletingTest) {
      let allTests = this.selectedTestsDetails;
      let deletingIds = allTests
        .filter((e) => e.name == deletingTest.name)
        .map((e) => e._id);
      this.testsData = this.testsData.filter(
        (testId) => !deletingIds.includes(testId)
      );
    },
    async fetchTestProfiles() {
      try {
        this.loading = true;
        let profileId = this.$route.params.profile_id;
        await this.$store.dispatch("testProfiles/fetchTestProfilesById", {
          profileId: profileId,
        });
        if (this.getSingleTestProfile && this.getSingleTestProfile.data) {
          this.case_type = this.getSingleTestProfile.data.case_type;
          this.getAllTests();

          this.testsData = this.getSingleTestProfile.data.lab_test_ids;
          this.testProfileName = this.getSingleTestProfile.data.title;
          if (
            this.getSingleTestProfile &&
            this.getSingleTestProfile.data &&
            this.getSingleTestProfile.data.check_list_items
          ) {
            this.toxicology_ereq_form =
              this.getSingleTestProfile.data.check_list_items || [];
            this.setCheckListData()
          }
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getAllTests() {
      await this.$store.commit("errors/clear", null, { root: true });
      this.loading = true;
      await this.$store.dispatch("labTests/fetchAllLabTests", {
        get_all: true,
        case_type_code: this.case_type,
      });
      this.loading = false;
      this.selectedPanel = this.getAllLabTests;

      this.selectedPanelTests = this.selectedPanel;

      if (
        this.selectedCaseTypes &&
        this.selectedCaseTypes.length &&
        this.selectedCaseTypes[0]
      ) {
        this.activeName = this.selectedCaseTypes[0].label;
      }
    },
    getCategoryWiseTestsData(caseType, category) {
      let data = [];
      if (this.searchString) {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === caseType &&
            el.category_code === category &&
            (el.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
              el.category
                .toLowerCase()
                .includes(this.searchString.toLowerCase()))
        );
      } else {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === caseType && el.category_code === category
        );
      }
      return this.sortByKey(data, "name");
    },
    getSelectedDataObject() {
      this.selectedPanelTests.forEach((test) => {
        if (this.testsData.indexOf(test._id) != -1) {
          this.selectedData.push(test._id);
        }
      });
    },
    getCaseCategoryData(caseType) {
      const data = this.getAllLabTests.filter(
        (el) =>
          el.case_type_code === caseType &&
          (!this.searchString ||
            el.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
            el.category.toLowerCase().includes(this.searchString.toLowerCase()))
      );
      let flags = [],
        output = [],
        outputWithFullDetails = [],
        l = data.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[data[i].category_code]) continue;
        flags[data[i].category_code] = true;
        output.push(data[i].category_code);
        outputWithFullDetails.push(data[i]);
      }
      return outputWithFullDetails;
    },

    checkAll(data, caseType, category) {
      let selectedTestData = this.getCategoryWiseTestsData(
        caseType,
        category
      ).map((item) => item._id);
      if (data) {
        for (let i = 0; i < selectedTestData.length; i++) {
          if (this.testsData.indexOf(selectedTestData[i]) == -1) {
            this.testsData.push(selectedTestData[i]);
          }
        }
      } else {
        for (let i = 0; i < selectedTestData.length; i++) {
          let index = this.testsData.indexOf(selectedTestData[i]);
          if (index > -1) {
            this.testsData.splice(index, 1);
          }
        }
      }
    },

    isCheckAll(caseType, category) {
      let selectedTestData = this.getCategoryWiseTestsData(
        caseType,
        category
      ).map((item) => item._id);

      return selectedTestData.every((item) => {
        return this.testsData.indexOf(item) !== -1;
      });
    },
    async addTests() {
      try {
        this.getSelectedDataObject();
        this.loading = true;
        this.selectedData = [...new Set(this.selectedData)];
        let params = {
          lab_test_ids: this.selectedData,
          title: this.testProfileName,
          case_type: this.case_type,
        };
        if (this.toxicology_ereq_form && this.toxicology_ereq_form.length) {
          params.check_list_items = this.toxicology_ereq_form;
        }
        await this.$store.dispatch("testProfiles/addTestProfiles", params);
        this.loading = false;
        if (this.getTestProfilesAddStatus) {
          successNotification("Test Profile Created Successfully");
          this.$router.push({ name: "AllProfiles" });
        } else {
          if (!this.getErrors || this.getError) {
            errorNotification("Error while creating test profile");
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateTests() {
      try {
        this.getSelectedDataObject();
        this.loading = true;
        this.selectedData = [...new Set(this.selectedData)];
        let params = {
          lab_test_ids: this.selectedData,
          title: this.testProfileName,
          case_type: this.case_type,
          profileId: this.$route.params.profile_id,
        };
        if (this.toxicology_ereq_form && this.toxicology_ereq_form.length) {
          params.check_list_items = this.toxicology_ereq_form;
        }
        await this.$store.dispatch("testProfiles/updateTestProfiles", params);
        this.loading = false;
        if (this.getTestProfilesUpdateStatus) {
          successNotification("Test Profile Updated Successfully");
          this.$router.push({ name: "AllProfiles" });
        } else {
          if (!this.getErrors || this.getError) {
            errorNotification("Error while updating test profile");
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "/src/assets/scss/mixins/app.scss";
@mixin search-bar {
  border-radius: 5px;
  height: 30px;
  border: none;
}
#add-test-profile {
  border-radius: 10px 10px 0 0;
  .addTests {
    background-color: #ffffff;
    &__navbar {
      align-items: center;
      background: #bf1c39;
      border-radius: 5px 6px 0px 0px;
      padding: 22px 15px 22px 15px;
      color: #fff;
      .el-input__inner {
        border-radius: 5px;
        height: 30px;
        border: none;
      }
      .err {
        color: #fff;
        font-weight: 600;
        position: absolute;
        bottom: -22px;
        font-size: 14px;
        text-shadow: 0 0 2px #ff1e46;
      }
      .el-input--suffix .el-input__inner {
        @include search-bar;
      }
    }
    &__testLists {
      .selection-tests-group {
        .test-panels {
          .el-tabs {
            .el-tabs__item.is-active {
              color: #0a5da6;
            }
            .el-tabs__active-bar {
              background-color: #0a5da6;
              border-radius: 25px;

              height: 1.5px;
            }
            .el-tabs__nav-wrap {
              padding: 0 20px;
            }
            .el-tabs__nav-wrap::after {
              background-color: #e7e7f7;
              height: 1px;
            }
            .el-tabs__content {
              height: 500px;
              overflow: auto;
              margin-right: 5px;
            }

            &::-webkit-scrollbar {
              width: 3px;
            }

            &::-webkit-scrollbar-thumb {
              background: #ccc;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #0a5da6;
            }
          }
          //
          .all-tests {
            padding-bottom: 15px;
            .each-test {
              &:not(:last-child) {
                margin-bottom: 10px;
              }
              .el-collapse {
                --el-collapse-border-color: none;
                .el-collapse-item__header {
                  border: none;
                  background: #f4f4f4;
                  border-radius: 6px;
                  color: #4f4f4f;
                  font-weight: 500;
                  padding: 0 10px;
                  height: auto;
                  height: 30px;
                  .all-orders-img {
                    width: 10px;
                    padding: 10px 5px 0 5px;
                    img {
                      width: 10px;
                      height: 10px;
                    }
                  }
                }
                .el-collapse-item__wrap {
                  border: none;
                }
                .el-collapse-item__header.is-active {
                  border: none;
                }
                .el-collapse-item__content {
                  --el-collapse-border-color: none;
                  padding: 10px 5px 15px 5px;
                  overflow: auto;
                }
              }
            }
          }
          .test-panels-list {
            display: flex;
            width: 100%;
            .test-panels-list-left {
              width: 50%;

              ul {
                display: flex;
                li {
                  color: #4f4f4f;
                  font-weight: 500;
                  font-size: 15px;
                }
              }
            }
            .test-panels-list-right {
              width: 48%;
              display: flex;
              justify-content: right;
              .el-button {
                padding: 0;
                border: none;
                color: #219653;
                background: transparent;
                &:active {
                  color: #219653;
                  background: transparent;
                }
              }
            }
          }
          .tests-list {
            ul {
              display: flex;
              li {
                padding: 10px 5px;
                .el-checkbox:last-of-type {
                  border: none;
                  background: #ffffff;
                  padding: 0 7px;
                }
              }
            }
          }
          .el-collapse-item__arrow {
            color: #4f4f4f;
            font-size: 21px;
            position: absolute;
            right: 20px;
          }
          @include checkbox-styles;
          .el-checkbox {
            margin-left: 5px;
          }
          .el-checkbox-group {
            display: grid;
            grid-template-columns: 48% 48%;
            align-items: center;

            .each-check-box {
              margin: 5px 15px 5px 0;
            }
            .el-checkbox__label {
              white-space: pre-wrap;
              line-height: 1.5;
            }
            .el-checkbox {
              height: auto;
            }
          }
        }
        .newProfileTox {
          .checklist-grid {
            margin-left: 0;
            .el-checkbox {
              display: flex;
              align-items: flex-start;
            }
            .el-checkbox__input {
              position: relative;
              top: 2px;
            }
          }
        }
      }
    }
    &__noContent {
      padding: 6rem;
      text-align: center;
    }
  }
}
</style>

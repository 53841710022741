<template>
  <section id="all-profiles" v-loading="loading">
    <div>
      <div
        class="container-sm-table"
        v-if="
          (getAllTestProfiles &&
            getAllTestProfiles.data &&
            getAllTestProfiles.data.length) ||
          this.searchString ||
          this.caseType ||
          this.caseStatus
        "
      >
        <div class="listings-navbar">
          <el-row>
            <el-col :lg="11">
              <ul class="d-flex list-items-group-15 barcode-print">
                <li>
                  <el-button
                    class="btn button-with-icon"
                    @click="goToAddProfile"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/plus-icon.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/plus-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    <span>ADD NEW PROFILE</span>
                  </el-button>
                </li>
                <li>
                  <div class="form-element input-with-icon search-input-lg">
                    <el-input
                      placeholder="Search Profiles"
                      v-model="searchString"
                      @input="getAutoCompleteProfiles()"
                      :clearable="true"
                    ></el-input>
                    <span>
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid icon"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </el-col>
            <el-col :lg="13">
              <ul
                class="d-flex flex-wrap justify-content-end list-items-group-15"
              >
                <li>
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="caseType"
                      placeholder="Select Case Type"
                      filterable
                      clearable
                      default-first-option
                      :reserve-keyword="false"
                      @change="fetchAllTestProfiles"
                    >
                      <el-option
                        v-for="(caseType, index) of getCaseTypes"
                        :key="index"
                        :label="caseType.label"
                        :value="caseType.value"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li>
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="caseStatus"
                      class="m-2"
                      placeholder="Select Status"
                      size="large"
                      @change="fetchAllTestProfiles"
                      default-first-option
                      clearable
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
        <div
          class="profile-cards"
          v-loading="loading"
          v-if="getAllTestProfiles.data.length > 0"
        >
          <div
            class="card"
            v-for="(profile, index) in getAllTestProfiles.data"
            :key="index"
          >
            <div class="card__title">
              <h2 class="header">{{ profile.title }}</h2>
            </div>
            <div class="card__body">
              <div class="case-type">
                <h2 class="card-subtitle">Case Type</h2>
                <p class="caseTypeTag">
                  {{ mapCaseType(profile.case_type) }}
                </p>
              </div>
              <div class="text-includes-block">
                <p class="text-includes">No of Test Includes</p>
                <p class="count">{{ profile.total_tests }}</p>
              </div>
              <div class="profile-status">
                <p class="status-text">Test Profile Status</p>

                <div class="column-status">
                  <el-dropdown
                    class="status-dropdown"
                    @command="(command) => changeStatus(command, profile._id)"
                    trigger="click"
                  >
                    <el-button :class="getStatusType(profile.status)">
                      <span class="pr-5">{{
                        profile.status ? "Active" : "Inactive"
                      }}</span>
                    </el-button>

                    <template #dropdown>
                      <el-dropdown-menu class="status-dropdown-menu">
                        <el-dropdown-item
                          command="true"
                          v-if="profile.status !== true"
                        >
                          <span class="px-10">ACTIVE</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          command="false"
                          v-if="profile.status !== false"
                        >
                          <span class="px-10">INACTIVE</span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <div class="card__footer">
              <ul>
                <li>
                  <el-button
                    class="card-btn"
                    @click="editTestProfiles(profile._id)"
                  >
                    <img
                      class="action-active"
                      src="@/assets/images/icons/actions/edit-white.svg"
                      alt="icon"
                    />
                    Edit
                  </el-button>
                </li>
                <li>
                  <el-button
                    class="card-btn delete"
                    @click="onDelete(profile._id)"
                  >
                    <img
                      class="action-active"
                      src="@/assets/images/icons/actions/delete-white.svg"
                      alt="icon"
                    />
                    Delete
                  </el-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="no-data-block" v-else>
          <p class="text-no-data">No data</p>
        </div>
        <div
          class="listings listings__pagination"
          v-if="getAllTestProfiles.total > 10"
        >
          <el-pagination
            v-model:currentPage="page"
            v-model:page-size="pageSize"
            :page-sizes="[25, 50, 75, 100]"
            layout=" prev, pager, next, jumper, sizes ,total"
            :total="getAllTestProfiles.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div class="no-data-bock" v-else>
        <div class="bg-image">
          <img
            src="@/assets/images/backgrounds/no-data-img.png"
            class="bg-image"
            alt="No data Background"
          />
        </div>
        <div class="no-data-content">
          <h3>
            <span class="special-text"> No data </span> start your own test
            profile
          </h3>
          <el-button class="btn button-with-icon" @click="visible = false">
            <span class="mr-8">
              <img
                src="@/assets/images/icons/plus-icon.svg"
                alt="icon"
                class="inactive"
              />
              <img
                src="@/assets/images/icons/plus-icon.svg"
                alt="icon"
                class="active"
              />
            </span>
            <span @click="goToAddProfile">Add Profile</span>
          </el-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import FilterHelper from "@/mixins/FilterHelper";
import caseDataHelper from "@/mixins/caseDataHelper";

export default {
  data() {
    return {
      visible: true,
      loading: false,
      caseType: "",
      caseStatus: "",
      page: 1,
      pageSize: 25,
      searchString: "",
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      caseTypes: caseConstants.CASE_TYPES_DATA,
      options: [
        {
          value: "true",
          label: "Active",
        },
        {
          value: "false",
          label: "InActive",
        },
      ],
      search_case_types: [],
    };
  },
  mixins: [FilterHelper, caseDataHelper],
  computed: {
    ...mapGetters("testProfiles", [
      "getAllTestProfiles",
      "getTestProfileDeleteStatus",
      "getTestProfileDeleteError",
      "getTestProfileStatusUpdate",
    ]),
    getStatusType() {
      return (statusVal) => {
        const status = statusVal;
        if (status == true) {
          return "btn-success";
        } else if (status == false) {
          return "btn-danger";
        } else {
          return "";
        }
      };
    },
    getCaseTypes() {
      return this.search_case_types;
    },
  },
  async mounted() {
    this.setQueryParamsData();
    await this.getLabCaseTypes();

    await this.fetchAllTestProfiles();
  },
  methods: {
    getLabCaseTypes() {
      this.search_case_types = this.getLabBasedCaseTypes(
        caseConstants.TEST_CASE_TYPES_DATA
      );
    },
    mapCaseType(caseType) {
      return this.caseTypeMap[caseType] || caseType;
    },
    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
      };
      if (this.searchString) {
        params.search_string = this.searchString;
      }
      if (this.caseType) {
        params.case_type = this.caseType;
      }
      if (this.caseStatus != "") {
        params.status = this.caseStatus;
      }
      return params;
    },
    async fetchAllTestProfiles() {
      try {
        let params = this.prepareParams();
        this.loading = true;
        await this.$store.dispatch("testProfiles/fetchTestProfiles", params);
        this.updateQueryParams(params);
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedProfilePage: params.page,
        selectedProfileString: params.search_string,
        selectedProfilePageSize: params.limit,
        selectedProfileCaseType: params.case_type,
        selectedProfileStatus: params.status,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    setQueryParamsData() {
      this.page = this.$route.query.selectedProfilePage
        ? parseInt(this.$route.query.selectedProfilePage)
        : 1;
      this.pageSize = this.$route.query.selectedProfilePageSize
        ? parseInt(this.$route.query.selectedProfilePageSize)
        : 25;
      if (this.$route.query.selectedProfileString) {
        this.searchString = this.$route.query.selectedProfileString;
      }
      if (this.$route.query.selectedProfileCaseType) {
        this.caseType = this.$route.query.selectedProfileCaseType;
      }

      if (this.$route.query.selectedProfileStatus) {
        this.caseStatus = this.$route.query.selectedProfileStatus;
      }
    },

    getAutoCompleteProfiles() {
      setTimeout(() => {
        this.page = 1;
        this.fetchAllTestProfiles();
      }, 600);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllTestProfiles();
    },

    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllTestProfiles();
    },
    goToAddProfile() {
      this.$router.push({ name: "AddTestProfile" });
    },
    editTestProfiles(profile) {
      this.$router.push({
        name: "UpdateTestProfile",
        params: {
          profile_id: profile,
        },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    async onDelete(id) {
      this.$confirm("Are you sure to delete test profile", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteTestProfile(id);
      });
    },
    async deleteTestProfile(id) {
      try {
        await this.$store.dispatch("testProfiles/deleteTestProfile", {
          profileId: id,
        });
        if (this.getTestProfileDeleteStatus) {
          successNotification("Test profile deleted successfully");

          this.loading = true;

          await this.fetchAllTestProfiles(this.page);

          this.loading = false;
        } else {
          errorNotification(
            this.getTestProfileDeleteError ||
              "Error while deleting test profile"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async changeStatus(command, id) {
      let params = {
        status: command,
        profileId: id,
      };
      await this.updateStatus(params);
    },
    async updateStatus(params) {
      this.loading = true;
      await this.$store.dispatch("testProfiles/changeTestProfileStatus", {
        status: params.status,
        profileId: params.profileId,
      });
      this.loading = false;
      if (
        this.getTestProfileStatusUpdate &&
        this.getTestProfileStatusUpdate.message
      ) {
        successNotification(this.getTestProfileStatusUpdate.message);
        this.fetchAllTestProfiles(this.page);
      }
    },
  },
};
</script>
<style lang="scss">
@mixin button-css {
  border-radius: 4px !important;
  padding: 6px 5px;
  height: auto;
  min-height: 28px;
  line-height: 1;
  width: fit-content;
  background: #ffffff;
  border: 0.6px solid #4bb543;
  color: #4bb543;
  min-width: 80px;
  font-weight: 500 !important;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px !important;
  &:hover,
  &:active,
  &:focus {
    background: #f2f2f2;
    border: 0.6px solid #4bb543;
    color: #4bb543;
  }
}
#all-profiles {
  .no-data-bock {
    text-align: center;
    padding: 15px;
    .bg-image {
      text-align: center;
      .bg-image {
        width: 400px;
      }
    }
    .no-data-content {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;
      margin-top: 55px;

      h3 {
        text-transform: uppercase;
        font-size: 22px;
        color: #273480;
        .special-text {
          color: #bf1b39;
          padding-right: 5px;
        }
      }
      .btn.button-with-icon {
        min-height: 32px;
        max-height: 32px;
        padding: 3px 10px 3px 10px;
        background-color: #c7d0f866;
        border: none;

        &:hover,
        &:active,
        &:focus {
          color: #273480;
          background-color: #c7d0f866;
          border: none;
        }
      }
    }
  }
}
.profile-cards {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  margin-top: 10px;
  max-height: calc(100vh - 166px);
  padding-right: 10px;
  overflow: scroll;

  .card {
    margin: 5px 0;
    background: #ffffff;
    border-radius: 6.40567px;
    border-radius: 7px;
    border: 0.634px solid #d9d9d9;
    box-shadow: 0px 10.143px 27.894px -7.607px rgba(24, 39, 75, 0.12);
    &__title {
      height: 65px;
      .header {
        color: #ffffff;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        border-radius: 0px 10px 10px 0px;
        width: 75%;
        height: 38px;
        padding: 5px 20px;
        background-color: #273480;
        margin-bottom: 10px;
        position: relative;
        top: 12px;
      }
    }
    &__body {
      padding: 5px 15px 10px;
      .case-type {
        .card-subtitle {
          font-size: 14px;
          color: #273480;
          font-weight: 600;
          text-transform: uppercase;
        }
        .caseTypeTag {
          font-size: 14px;
          font-weight: 600;
          color: #454545;
          height: 20px;
          width: max-content;
          line-height: 20px;
          padding: 3px 10px;
          margin: 1px 5px 0 0;
          border-radius: 6px;
          border: 0.634px solid #d9d9d9;
          background: #fff;
          position: relative;
          bottom: 1px;
        }
      }
      .text-includes-block {
        padding: 5px 0;

        .text-includes {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          color: #273480;
        }
        .count {
          padding: 0 10px;
          color: #000000;
          font-size: 13px;
          width: max-content;
          font-weight: 600;
          border-radius: 6px;
          border: 0.634px solid #d9d9d9;
          background: #fff;
          position: relative;
          bottom: 1px;
        }
      }
      .profile-status {
        .status-text {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          color: #273480;
        }
        .btn-success {
          @include button-css;
          background: #4bb543;
          color: #ffffff;
          border-radius: 6px;
          font-size: 14px !important;
          border: none;
          text-transform: uppercase;
          &:hover,
          &:active,
          &:focus {
            background: #4bb543;
            color: #ffffff;
            border-radius: 6px;
            border: none;
          }
          &.is-disabled {
            opacity: 0.5;
            background: #ffffff;
            border: 0.6px solid #4bb543;
            color: #4bb543;
            &:hover {
              opacity: 0.5;
              background: #ffffff;
              border: 0.6px solid #4bb543;
              color: #4bb543;
            }
          }
        }
        .btn-danger {
          @include button-css;
          background: #bf1b39;
          color: #ffffff;
          border-radius: 6px;
          font-size: 14px !important;
          border: none;
          text-transform: uppercase;
          &:hover,
          &:active,
          &:focus {
            background: #bf1b39;
            color: #ffffff;
            border-radius: 6px;
            border: none;
          }
          &.is-disabled {
            opacity: 0.5;
            background: #bf1b39;
            border: 0.6px solid #bf1b39;
            color: #ffffff;
            &:hover {
              opacity: 0.5;
              background: #bf1b39;
              border: 0.6px solid #bf1b39;
              color: #ffffff;
            }
          }
        }
        .btn-active {
          @include button-css;
        }
        .status-dropdown-menu {
          padding: 1px !important;
          border: none !important;

          .el-dropdown-menu__item:not(:last-child) {
            padding: 2px 15px;
            line-height: 24px;
            border-bottom: 0.5px solid rgba(130, 130, 130, 0.3882352941);
          }

          .el-dropdown-menu__item {
            font-size: 13px !important;
            // line-height: 24px;
            line-height: 28.8px;

            &.is-disabled {
              padding: 0 15px !important;
              line-height: 28px;
            }
          }

          .el-dropdown-menu__item:focus,
          .el-dropdown-menu__item:not(.is-disabled):hover {
            color: #f8f7fa !important;
            font-weight: 500 !important;
            background: #0a5da6 !important;
          }
        }
      }
    }
    &__footer {
      padding: 10px 15px 10px;
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        li {
          margin-right: 10px;
          .card-btn {
            min-height: 36px;
            padding: 8px 24px !important;
            color: #454545;
            border-radius: 6.6px;
            width: 100%;
            border: none;
            transition: all 0.3s ease-in-out;
            background: #fff;
            &:hover {
              border-radius: 6.6px;
              background: #f2f2f2;
              border: none;
              width: 100%;
              transition: all 0.3s ease-in-out;
            }
            &.delete {
              &:hover {
                border-radius: 6.6px;
                background: #f2f2f2;
                border: none;
                width: 100%;
                transition: all 0.3s ease-in-out;
              }
            }
            span {
              display: flex;
              align-items: center;
            }
            .action-active {
              padding-right: 6px;
              position: relative;
              bottom: 1px;
            }
          }
        }
      }
    }
  }
}
.no-data-block {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin-top: 10px;

  .text-no-data {
    font-size: 18px;
    color: #eb5757;
    font-weight: 500;
  }
}
</style>
